<template>
  <div class="del-main-box">
    <div class="del-top-box">删除你的账号</div>
    <div class="del-text-box">
      我们遵循Google Play的标准规范，为您提供快速 便捷的数据删除服务，你应当知晓，数据删除是不可
      逆转的行为，因此我们在此提醒你，在你做此行动之 前，你应当进行了充分的妥善考虑。
      <br />
      接下来，请告知我们如何删除你的数据，请选择相关的选项，采取你的行动:
    </div>
    <div class="del-center-box" @click="toInfo">
      <div class="del-center-top">删除你的账号</div>
      <div class="del-text-box">
        在您主动删除账户之后，我们将停止为您提供产品 或服务，并根据适用法律的要求删除或匿名化处理您
        的个人信息，因法律法规规定需要留存的除外。
      </div>
      <div class="del-center-right">申请删除我的账号</div>
    </div>
    <div class="del-bottom-text">Copyright © 2023-2024 dosilm All Rights Reserved</div>
    
  </div>
</template>
<script>
import { Swipe, SwipeItem, Grid, GridItem, Search, Toast } from "vant";
export default {
  // mixins: [mixinViewModule],
  data() {
    return {
      dataForm: {
        phone: "",
        password: "",
        reason: "",
        contactInfo: "",
      },
    };
  },
  components: {
    [Search.name]: Search,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Toast.name]: Toast,
  },
  created() {
    // this.getIpdatas();
    // this.getWxNews();
  },
  methods: {
    toInfo() {
      this.$router.push({ path: "/deleteAccountInfo" });
    },
  },
};
</script>
<style lang="less" scoped>
.del-top-box {
  color: #957ec2;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 15px;
}
.del-text-box {
  padding: 0 15px;
  color: #2d2d2d;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  text-indent: 2em /* 两个空格的宽度 */;
  text-align: left;
}
.del-privacy-box {
  padding: 0 15px;
  text-align: right;
  color: #957ec2;
  font-size: 16px;
}
.del-center-box {
  padding: 20px 15px;
  width: calc(100% - 60px);
  min-height: 100px;
  margin-top: 20px;
  margin-left: 15px;
  border: 1px solid #2d2d2d;
}
.del-center-input {
  padding-top: 20px;
}
.del-input-text {
  text-align: left;
  font-size: 14px;
  color: #2d2d2d;
}
.del-center-input input {
  width: calc(100% - 50px);
  margin-left: -30px;
  height: 30px;
  font-size: 14px;
  padding-left: 15px;
}
.del-center-input textarea {
  width: calc(100% - 50px);
  margin-left: -30px;
  font-size: 14px;
  padding-left: 15px;
  height: 80px;
}
.del-center-top {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #2d2d2d;
}
.del-center-btn {
  width: 40%;
  height: 40px;
  background: #957ec2;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  margin: 0 auto;
}
.del-bottom-text {
  color: #2d2d2d;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
}
.del-main-box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.del-center-right {
  color: #957ec2;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  margin-top: 10px;
}
</style>
